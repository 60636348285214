.endweb_box {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    top: 0;
    bottom: 0;
    // background-color: skyblue;
    min-width: 1400px;

    .endweb_header_box {
        width: 100%;
        height: 100px;
        background-color: rgba(0, 201, 168, 1);

        .endweb_header {
            // position: absolute;
            // top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            min-width: 1200px;
            z-index: 9999;
            display: flex;
            flex-direction: row;

            .header_left {
                flex: 1;
                // background-color: red;
                display: flex;
                flex-direction: row;

                .header_left_content {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // background-color: green;
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    cursor: pointer;
                    min-width: 80px;

                    .divimg {
                        width: 80%;
                        height: 40%;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                }

                .header_left_content:hover {
                    color: #21D3A2;
                    transition: 0.2s;
                }
            }

            .header_right {

                flex: 1.5;
                // background-color: pink;
                display: flex;
                flex-direction: row;

                // min-width: 700px;
                .inputsousuo {
                    flex: 2;
                    // background-color: green;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 30px;

                    .ant-input {
                        background-color: rgba(255, 255, 255, 0);
                        color: #ffffff !important;
                        border: 1px solid rgba(255, 255, 255, 0.5);

                        &::placeholder {
                            color: #ffffff !important;
                        }
                    }
                }

                .header_right_other {
                    flex: 1;
                    // background-color: yellow;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    color: #fff;
                    min-width: 80px;
                    margin-left: 10px;



                    .ant-select-selector {
                        background: none !important;
                        border: none !important;
                        color: #ffffff !important;
                        font-size: 16px;
                    }

                    .ant-select-item-option-content {
                        color: #ffffff !important;
                    }

                    .btnone {
                        width: 80px;
                        background-color: rgba(255, 255, 255, 0);
                        border: 1px solid #fff;
                        border-radius: 20px;
                    }

                    .btntwo {
                        width: 80px;
                        background-color: rgba(255, 255, 255, 1);
                        border-radius: 20px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #26C6A6;
                        line-height: 26px;
                    }
                }

                .header_right_others {
                    flex: 1;
                }
            }
        }
    }

    .endweb_center {
        position: relative;
        width: 100%;
        height: 13rem;
        // background-color: rgba(0, 201, 168, 1);
        min-width: 1200px;
        // z-index: -1;
        border: none;

        // display: flex;
        // flex-direction: row;
        // justify-content: center;
        .backgrounnd {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -100;
        }

        .msxjs {
            position: absolute;
            width: 12rem;
            height: 6rem;
            // background-color: black;
            top: 2.5rem;
            left: 8rem;
            bottom: 0;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .msxrw {
            position: absolute;
            right: 5.5rem;
            // top: 0;
            bottom: 0;
            width: 12rem;
            height: 11rem;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .msx_title_sw_btn {
            position: absolute;
            height: 1.2rem;
            width: 5rem;
            // background-color: pink;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 1rem;
            left: 14.4rem;
            min-height: 60px;

            .swhz_btn {
                width: 90%;
                height: 80%;
                font-size: 22px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                background-color: rgba(0, 150, 167, 1);
            }

            .cwgcs_btn {
                width: 90%;
                height: 80%;
                font-size: 22px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
                border-radius: 4px;
                border: 2px solid rgba(255, 255, 255, 0.29);
                background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .msx_title_sw_btnbtnfalse {
            position: absolute;
            height: 1.2rem;
            width: 10rem;
            // background-color: pink;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 1rem;
            left: 8.4rem;
            min-height: 60px;
        }

        .msx_title_sw_btns {
            position: absolute;
            height: 1.2rem;
            width: 5rem;
            // background-color: pink;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 1rem;
            left: 8rem;
            min-height: 60px;

            .swhz_btn {
                width: 90%;
                height: 80%;
                font-size: 22px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
                box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
                border-radius: 4px;
                border: 2px solid rgba(255, 255, 255, 0.29);
            }

            .cwgcs_btn {
                width: 90%;
                height: 80%;
                font-size: 22px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
                border-radius: 4px;
                border: 2px solid rgba(255, 255, 255, 0.29);
                background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .why_msx {
        width: 100%;
        height: 100px;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2A2A2A;
        margin-top: 10px;
    }

    .why_msx_conntent {
        width: 100%;
        height: 350px;
        // background-color: pink;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .why_msx_conntent_box {
            width: 7rem;
            height: 100%;
            min-width: 300px;
            // background-color: #21D3A2;
            margin-left: 1rem;
            margin-right: 1rem;
            display: flex;
            flex-direction: column;

            .why_msx_conntent_box_phone {
                width: 100%;
                height: 200px;
                // background-color: red;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .why_msx_conntent_box_title {
                width: 100%;
                height: 30px;
                font-size: 32px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #4F5156;
                display: flex;
                justify-content: center;
                align-items: center;
                // background-color: red;
            }

            .why_msx_conntent_box_HX {
                width: 100%;
                height: 15px;
                // background-color: pink;
                display: flex;
                justify-content: center;
                align-items: center;

                div {
                    width: 12%;
                    height: 4px;
                    // background-color: #21D3A2;
                }
            }

            .why_msx_conntent_box_con {
                flex: 1;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #93979E;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                text-align: center;
                // padding-top: 20px;
                line-height: 30px;
                // background-color: blue;
            }
        }
    }

    .Interpretationmodule {
        width: 100%;
        height: 550px;
        // background-color: pink;
        display: flex;
        flex-direction: column;

        .Interpretationmodule_Carousel {
            width: 100%;
            height: 510px;
            // background-color: red;
            display: flex;
            justify-content: center;
            align-items: center;

            .Interpretationmodule_Carousel_left {
                height: 100%;
                width: 200px;
                // background-color: skyblue;
                display: flex;
                justify-content: center;
                align-items: center;

                .Interpretationmodule_Carousel_left_btn {
                    width: 100px;
                    height: 100px;
                    // background-color: pink;
                    cursor: pointer;
                    margin-top: 80px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .Interpretationmodule_Carousel_right {
                height: 100%;
                width: 200px;
                // background-color: skyblue;
                display: flex;
                justify-content: center;
                align-items: center;

                .Interpretationmodule_Carousel_right_btn {
                    width: 98px;
                    height: 98px;
                    // background-color: pink;
                    cursor: pointer;
                    margin-top: 80px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .Interpretationmodule_Carousel_center {
                width: 50%;
                height: 100%;
                // background-color: blue;

                .Carousel_box {
                    margin: 0;
                    height: 510px;
                    color: #fff;
                    // background: #364d79;

                    .Carousel_box_tx {
                        width: 100%;
                        height: 200px;
                        // background-color: red;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .divimgbox {
                            width: 150px;
                            height: 150px;
                            border-radius: 50%;
                            overflow: hidden;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                    }

                    .Carousel_box_jr {
                        width: 100%;
                        height: 80px;
                        // background-color: pink;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 32px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #2A2A2A;
                    }

                    .Carousel_box_js {
                        width: 100%;
                        height: 110px;
                        font-size: 20px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #4F5156;
                        text-align: center;
                        // background-color: red;
                    }

                    .Carousel_box_name {
                        width: 100%;
                        height: 50px;
                        // background-color: pink;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .Carousel_box_name_hx {
                            width: 200px;
                            height: 100%;
                            // background-color: red;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;

                            div {
                                width: 80px;
                                height: 2px;
                                background: #DCDEE2;
                            }
                        }

                        .Carousel_box_name_hxs {
                            width: 200px;
                            height: 100%;
                            // background-color: red;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            div {
                                width: 80px;
                                height: 2px;
                                background: #DCDEE2;
                            }
                        }

                        .Carousel_box_name_hx_name {
                            width: 100px;
                            height: 100%;
                            // background-color: yellow;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 24px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #8E9196;
                        }
                    }
                }
            }

        }
    }

    .joiningprocess {
        width: 100%;
        height: 100px;
        background-color: rgba(248, 248, 248, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2A2A2A;
    }


    .Operationprocess {
        width: 100%;
        height: 220px;
        // background-color: skyblue;
        background-color: rgba(248, 248, 248, 1);
        display: flex;
        justify-content: center;
        align-items: center;

        .modal_Operationprocess {
            width: 70%;
            height: 100%;
            // background-color: pink;
            display: flex;
            align-items: center;
            justify-content: center;

            .modal_Operationprocess_some {
                width: 100px;
                height: 130px;
                // background-color: red;
                display: flex;
                flex-direction: column;
                margin-left: 20px;
                margin-right: 20px;

                .modal_Operationprocess_some_icon {
                    width: 100%;
                    height: 100px;
                    // background-color: yellow;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                    }

                    .leftIcon {
                        width: 57px;
                        height: 57px;
                    }
                }

                .modal_Operationprocess_some_title {
                    flex: 1;
                    text-align: center;
                    font-size: 20px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #58595D;
                    padding-top: 10px;
                }
            }
        }
    }

    .qanda {
        width: 100%;
        height: 100px;
        background-color: rgba(248, 248, 248, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2A2A2A;
        margin-top: 10px;
    }

    .qanda_content {
        width: 100%;
        height: 400px;
        background-color: rgba(248, 248, 248, 1);
        display: flex;
        justify-content: center;
        align-items: center;

        .qanda_content_box {
            width: 60%;
            height: 100%;
            // background-color: blue;
            display: flex;
            flex-direction: column;

            .qanda_content_some {
                flex: 1;
                // background-color: red;
                display: flex;
                flex-direction: column;

                .qanda_content__top {
                    flex: 1;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-start;
                    font-size: 20px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #58595D;
                    // background-color: pink;
                }

                .qanda_content__left {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999CA0;
                }
            }
        }
    }

    .welcometocall {
        width: 100%;
        height: 100px;
        background-color: rgba(248, 248, 248, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        font-weight: 700;
        color: black;

        .welcometocall_btn {
            width: 200px;
            height: 50px;
            // background-color: pink;
            border-radius: 10px;
            border: 1px solid rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
        }

        .welcometocall_btn:hover {
            background-color: rgba(18, 199, 170, 1);
            transition: 0.2s;
            color: #fff;
            border: none;
        }
    }

    .msx_footer {
        width: 100%;
        height: 200px;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;

        .msx_footer_left {
            width: 40%;
            height: 100%;
            // background-color: aqua;
            display: flex;
            flex-direction: row;

            .msx_footer_left_l {
                width: 80%;
                height: 100%;
                // background-color: pink;
                display: flex;
                flex-direction: column;

                .msx_footer_left_mould {
                    flex: 1;
                    display: flex;
                    flex-direction: row;

                    .msx_footer_left_some {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 0.2rem;
                        // background-color: skyblue;
                        color: rgba(255, 255, 255, 0.8);
                        cursor: pointer;
                    }

                    .msx_footer_left_some:hover {
                        color: rgba(255, 255, 255, 1);
                        transition: 0.5s;
                        font-size: 0.27rem;
                    }

                    .msx_footer_left_somes:hover {
                        color: rgba(255, 255, 255, 1);
                        transition: 0.5s;
                        font-size: 0.32rem;
                    }

                    .msx_footer_left_somes {
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 0.3rem;
                        // background-color: skyblue;
                        font-weight: 500;
                        color: rgba(255, 255, 255, 0.8);
                        cursor: pointer;
                    }
                }
            }

            .msx_footer_right_r {
                width: 20%;
                height: 100%;
                // background-color: black;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                min-width: 150px;

                .two-dimensional_code {
                    width: 100%;
                    height: 80%;
                    // background-color: pink;
                    display: flex;
                    flex-direction: column;
                    // justify-content: center;
                    align-items: center;

                    .dimensional_code_icon {
                        width: 150px;
                        height: 150px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .dimensional_code_title {
                        width: 100%;
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 12px;
                        color: #ffffff;
                    }
                }
            }
        }

        .msx_footer_right {
            width: 26%;
            height: 100%;
            // background-color: red;
            display: flex;
            justify-content: center;
            align-items: center;

            .msx_footer_right_content {
                width: 70%;
                height: 60%;
                display: flex;
                flex-direction: column;
                border-left: 1px solid rgba(255, 255, 255, 0.1);

                .msx_footer_right_number {
                    flex: 1;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    font-size: 0.5rem;
                    // background-color: green;
                    color: green;
                }

                .msx_footer_right_time {
                    flex: 1;
                    text-align: center;
                    font-size: 0.4rem;
                    color: #fff;
                }

            }
        }
    }

    .copyright {
        width: 100%;
        height: 50px;
        background-color: rgba(0, 0, 0, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.2rem;
        color: rgba(255, 255, 255, 0.7);
    }

    .Business_cooperation_modal {
        position: absolute;
        width: 500px;
        height: 500px;
        background-color: black;
    }

    .FloatButtonDIV {
        width: 310px;
        height: 150px;
        position: absolute;
        // background-color: red;
        right: 60px;
        top: -37px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        font-size: 30px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.88);
        background-color: rgba(255, 255, 255, 1);
        line-height: 1.5714285714285714;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        cursor: pointer;
        overflow: hidden;
        z-index: 99;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

        .Customerservicetype {
            flex: 1;
            margin-top: 2px;
            margin-bottom: 2px;
            // background-color: pink;
            display: flex;
            flex-direction: row;

            .Customerservicetype_left {
                width: 50px;
                height: 100%;
                // background-color: blue;
                display: flex;
                justify-content: center;
                align-items: center;

                div {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    overflow: hidden;

                    .leftIcon {
                        width: 25px;
                        height: 25px;
                        filter: drop-shadow(#21D3A2 80px 0);
                    }

                    &:hover {
                        .leftIcon {
                            margin-left: -160px;
                        }
                    }
                }
            }

            .Customerservicetype_center {
                flex: 1;
                display: flex;
                flex-direction: column;

                .Customerservicetype_center_top {
                    flex: 1;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-start;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2A2A2A;
                    line-height: 22px;
                }

                .Customerservicetype_center_bottom {
                    flex: 1;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #26C6A6;
                    line-height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .Customerservicetype_center_bottoms {
                    flex: 1;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #2A2A2A;
                    line-height: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .Customerservicetype_riht {
                width: 30px;
                height: 100%;
                // background-color: red;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 17px;
                    height: 17px;
                }
            }
        }

        .Customerservicetype:hover {
            background-color: rgba(0, 0, 0, 0.05);
            transition: .5s;

            &:hover {
                .leftIcon {
                    margin-left: -160px;
                }
            }
        }
    }

    .XonClose {
        position: absolute;
        width: 40px;
        height: 40px;
        // background-color: red;
        top: 4px;
        right: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.5);
    }
}

.successModal {
    width: 100%;
    height: 300px;
    // background-color: skyblue;
    display: flex;
    flex-direction: column;

    .successModal_imgLogo {
        width: 100%;
        height: 200px;
        // background-color: red;
        margin-top: -100px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 200px;
            height: 90%;
        }
    }

    .successModal_downloadTitle {
        width: 100%;
        height: 80px;
        // background-color: blue;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4F5156;
        line-height: 33px;

        img {
            width: 20px;
            height: 100%;
        }
    }

    .successModal_qr {
        width: 100%;
        height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // background: red;
        .successModal-qr-code{
            width: 120px;
            height: 120px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .successModal-qr-title{
            width: 100%;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            font-size: 20px;
            font-weight: 600;
        }
        .successModal_qr_box {
            width: 100px;
            height: 150px;
            display: flex;
            flex-direction: column;

            .successModal_qr_img {
                width: 100px;
                height: 100px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .successModal_qr_title {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #4F5156;
                line-height: 25px;
            }
        }
    }

    .successModal_qx {
        position: absolute;
        width: 100%;
        height: 50px;
        // background-color: red;
        // margin-top: 00px;
        top: 360px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 50px;
            height: 50px;
            cursor: pointer;
        }
    }
}

.zskfCancel {
    position: absolute;
    bottom: -50px;
    width: 100%;
    height: 40px;
    // background-color: red;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
}

.custom-class {
    height: 300px !important;
}

.ant-modal-content {
    position: relative !important;
    z-index: 99999999 !important;
}

//更改antdesign样式
.ant-select-selector {
    background-color: rgba(0, 0, 0, 0.03) !important;
    border: none !important;
    color: black !important;

}

.ant-select-item-option-content {
    color: black !important;
}

.ant-input {
    background-color: rgba(0, 0, 0, 0.03) !important;
    color: rgba(0, 0, 0, 1) !important;
    border: none !important;

    &::placeholder {
        color: #9498A1 !important;
    }
}

.ant-btn-default {
    background-color: rgba(255, 255, 255, 0) !important;
}

.ant-select-suffix {
    color: #ffffff !important;
}

.ant-input-search-button:not(.ant-btn-primary) {
    color: #ffffff !important;
}

.popupClassName {
    background-color: rgba(0, 0, 0, 0.3) !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.slick-dots li button {
    position: relative !important;
    display: block !important;
    width: 100% !important;
    height: 16px !important;
    color: transparent !important;
    font-size: 0 !important;
    background: rgba(0, 189, 162, 1) !important;
    border-color: rgba(0, 189, 162, 1) !important;
    border: 2px solid !important;
    border-radius: 20px !important;
    outline: none !important;
    cursor: pointer !important;
    // opacity: 0.3;
    transition: all 0.3s !important;
}

.FloatButton_box {
    position: fixed;
    bottom: 90px;
    right: 43px;
    width: 67px;
    height: 210px;
    // background-color: red;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    .floatButton_box_ys {
        position: relative;
        flex: 1;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);

        div {
            position: absolute;
            width: 100%;
            height: 15px;
            // background-color: red;
            bottom: 5px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #93979E;
            display: flex;
            justify-content: center;
            align-items: center;

        }
    }
}

.ant-float-btn {
    box-shadow: none;
}

@media screen and (max-width: 1500px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 6rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 14rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 12rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 7.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 6rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 8rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 1490px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 6rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 14rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 12rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 7.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 6rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 7.5rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 1350px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 6rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 15rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 12rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 8rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 6rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 8rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 1260px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 6rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 16rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 12rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 9.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 6rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 9rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 1200px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 16rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 12rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 10.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 8rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 1180px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 7.5rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 16.5rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 12rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 10.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 7.5rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 9rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 1154px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 7.5rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 17.5rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 12rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 10.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 7.5rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 9rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 1100px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 17.7rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 12rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 10.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 9rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 1080px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 18.4rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 12rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 12.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 9.5rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 1030px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 19.4rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 13rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 12.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 10.4rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 1013px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 19.7rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 12rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 13.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 10.5rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 972px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0.5rem !important;
        left: 20.7rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 12rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 13.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0.5rem !important;
        left: 11.5rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 969px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 20rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 13rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 12.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 11rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 920px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0.1rem !important;
        left: 22rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 13rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 13.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0.1rem !important;
        left: 11.8rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 910px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0.1rem !important;
        left: 23rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 13rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 13.8rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0.1rem !important;
        left: 11.8rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 870px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0.1rem !important;
        left: 24rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 13rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 14.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0.1rem !important;
        left: 13rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

// @media screen and (max-width: 860px) {
//     .msx_title_sw_btn {
//         position: absolute;
//         height: 1.2rem;
//         width: 8rem !important;
//         // background-color: pink;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         bottom: 0.1rem !important;
//         left: 24rem !important;
//         min-height: 60px;

//         .swhz_btn {
//             width: 90%;
//             height: 80%;
//             font-size: 0.4rem;
//             // min-width: 250px;
//             color: #ffffff;
//             font-weight: 600;
//             background-color: rgba(0, 150, 167, 1);
//         }

//         .cwgcs_btn {
//             width: 90%;
//             height: 80%;
//             font-size: 0.4rem;
//             // min-width: 250px;
//             font-weight: 600;
//             color: #ffffff;
//             box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
//             border-radius: 4px;
//             border: 2px solid rgba(255, 255, 255, 0.29);
//             background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
//             overflow: hidden;
//             white-space: nowrap;
//             text-overflow: ellipsis;
//         }
//     }


//     .msx_title_sw_btns {
//         position: absolute;
//         height: 1.2rem;
//         width: 8rem !important;
//         // background-color: pink;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         bottom: 0.1rem !important;
//         left: 10rem !important;
//         min-height: 60px;

//         .swhz_btn {
//             width: 90%;
//             height: 80%;
//             font-size: 0.4rem;
//             // min-width: 250px;
//             color: #ffffff;
//             font-weight: 600;
//             background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
//             box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
//             border-radius: 4px;
//             border: 2px solid rgba(255, 255, 255, 0.29);
//         }

//         .cwgcs_btn {
//             width: 90%;
//             height: 80%;
//             font-size: 0.4rem;
//             // min-width: 250px;
//             font-weight: 600;
//             color: #ffffff;
//             box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
//             border-radius: 4px;
//             border: 2px solid rgba(255, 255, 255, 0.29);
//             background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
//             overflow: hidden;
//             white-space: nowrap;
//             text-overflow: ellipsis;
//         }
//     }
// }

@media screen and (max-width: 800px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -0.1rem !important;
        left: 26rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 0.4rem;
            // min-width: 250px;
            font-weight: 600;
            color: #ffffff;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 15rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 16.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -0.1rem !important;
        left: 14.8rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 770px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -0.1rem !important;
        left: 28rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 15rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 17.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -0.1rem !important;
        left: 14.8rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 740px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -0.1rem !important;
        left: 29rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 16rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 18.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 8rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -0.1rem !important;
        left: 14.8rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 700px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 10rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0.1rem !important;
        left: 30rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 15rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 19.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 10rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 0.1rem !important;
        left: 15rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

@media screen and (max-width: 630px) {
    .msx_title_sw_btn {
        position: absolute;
        height: 1.2rem;
        width: 10rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -0.2rem !important;
        left: 35rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background-color: rgba(0, 150, 167, 1);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .msx_title_sw_btnbtnfalse {
        position: absolute;
        height: 1.2rem;
        width: 16rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: 1rem;
        left: 23.4rem !important;
        min-height: 60px;
    }

    .msx_title_sw_btns {
        position: absolute;
        height: 1.2rem;
        width: 10rem !important;
        // background-color: pink;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -0.2rem !important;
        left: 20rem !important;
        min-height: 60px;

        .swhz_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
        }

        .cwgcs_btn {
            width: 90%;
            height: 80%;
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            box-shadow: 8px 8px 20px 0px rgba(106, 149, 145, 0.1), -8px -8px 20px 0px rgba(255, 255, 255, 0.1);
            border-radius: 4px;
            border: 2px solid rgba(255, 255, 255, 0.29);
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}

// 通过微元素来给元素添加边框
// .slick-dots li button::before {
//     content: " ";
//     width: 300%;
//     height: 300%;
//     position: absolute;
//     // top: 50%;
//     left: 50%;
//     border: 1px solid green;
//     border-radius: 50%;
// }
.ant-message {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.welcome-call {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    font-weight: 600;
}

.welcome-call-x {
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: -50px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
}
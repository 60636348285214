.contentIntroduction {
    width: 100%;

    .module-i {
        width: 100%;
        height: 258px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;

        .module-i-title {
            font-size: 26px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2C2D;
            margin-top: 30px;
        }

        .module-i-img {
            width: 339px;
            height: 142px;
            margin-top: 20px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .module-other {
        width: 100%;
        height: 154px;
        display: flex;
        ;
        justify-content: center;
        align-items: center;
        background: #F7F8FC;

        img {
            width: 339px;
            height: 94px;
        }
    }

    .active {
        background: #fff;
    }

    .module-furnish {
        width: 100%;
        height: 402px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .furnish-backdrop {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        .module-furnish-title {
            margin-top: 30px;
            font-size: 24px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2C2D;
        }

        .module-furnish-imgIcon {
            width: 332.42px;
            height: 314.5px;
            margin-top: 24px;

            .cooperation-imgs {
                width: 100%;
                height: 100%;
            }
        }
    }

    .module-cooperation {
        width: 100%;
        height: 181px;
        display: flex;
        flex-direction: column;
        background: linear-gradient(140deg, #51DFBE 0%, #1AC7A4 100%);
        align-items: center;

        .module-cooperation-title {
            font-size: 24px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            margin-top: 30px;
        }

        .module-cooperation-img {
            width: 339px;
            height: 62px;
            margin-top: 15px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
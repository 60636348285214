.join-box {
    width: 100%;
    height: 649px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F7F8FC !important;
    position: relative;

    .join-title {
        font-size: 26px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2C2C2D;
        margin-top: 30px;
    }

    .join-img {
        width: 361px;
        height: 243px;
        margin-top: 10px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .join-form {
        position: absolute;
        top: 279px;
        // width: 339px;
        width: 90.4%;
        height: 340px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;

        .join-form-input {
            width: 91.1%;
            height: 238px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 20px;
            .join-form-item {
                height: 44px;
                width: 100%;
                display: flex;
                align-items: center;

                .item-left {
                    width: 50px;
                    min-width: 50px;
                    height: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    background-color: #fff;
                    color: #2C2C2D;

                    span {
                        color: red;
                        margin-right: 2px;
                    }
                }

                .item-right {
                    height: 100%;
                    flex: 1;
                    margin-left: 14px;
                    display: flex;
                    align-items: center;
                    position: relative;

                    .icon-select {
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        right: 12px;
                    }

                    .input-antd-style {
                        width: 100%;
                        height: 100%;
                    }
                    .adm-input-element{
                        font-size: 14px !important;
                        background: #F7F8FC !important;
                    }
                    .adm-text-area-element {
                        background: #F7F8FC;
                        border-radius: 4px 4px 4px 4px;
                        opacity: 1;
                        font-size: 14px !important;
                        height: 100%;
                        width: 100%;
                        padding-left: 12px;
                        padding-top: 12px;
                    }
                }
            }

            .active {
                height: 70px;
                width: 100%;

                .item-left {
                    display: flex;
                    align-items: flex-start;
                    padding-top: 12px;
                }
            }
        }

        .join-form-submit {
            margin-top: 18px;
            width: 91.1%;
            height: 44px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            overflow: hidden;

            .join-form-btn {
                width: 100%;
                height: 100%;
                border: none;
                background: #26C6A6;
                font-size: 16px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
.sw-modal-alert-title{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.adm-button:not(.adm-button-default) {
    border: none;
    background: #26C6A6;
}
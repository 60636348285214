.header-box {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .header-store-titleTop {
        display: flex;
        align-items: center;
        height: 45px;
        margin-top: 42px;
        font-size: 35px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
    }

    .header-store-titleBottom {
        display: flex;
        align-items: center;
        height: 45px;
        font-size: 35px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
    }

    .header-store-button {
        width: 147px;
        height: 33px;
        background: #26C6A6;
        border-radius: 4px 4px 4px 4px;
        opacity: 0.98;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 14px;
    }
}
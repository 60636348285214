.public-account {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #D4FFF6 0%, rgba(255, 255, 255, 0.64) 32%, rgba(240, 242, 255, 0) 100%);

    .public-account-logo {
        width: 100%;
        height: 9.4rem;
        margin-top: 7.9rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 16.5rem;
            height: 100%;
        }
    }

    .public-account-title {
        width: 100%;
        height: 2.2rem;
        margin-top: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        letter-spacing: 1px;
    }

    .public-account-input {
        width: 100%;
        height: 13.8rem;
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        .public-account-input-top {
            width: 31.9rem;
            height: 6.4rem;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid #F5F5F5;

            .public-account-input-top-title {
                width: 6rem;
                min-width: 60px;
                height: 100%;
                font-size: 1.6rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                display: flex;
                justify-content:flex-start;
                align-items: center;
            }

            .public-account-input-top-input {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                .adm-input-element {
                    flex: auto;
                    display: inline-block;
                    box-sizing: border-box;
                    width: 100%;
                    max-width: 100%;
                    height: 5.4rem;
                    max-height: 100%;
                    padding: 10;
                    margin: 0;
                    color: var(--color);
                    font-size: 1.4rem;
                    /* background: transparent; */
                    border: 0;
                    outline: none;
                    appearance: none;
                    min-height: 40px;
                    text-align: var(--text-align);
                    /* background: #F9F9FA; */
                    /* border: 1px solid #F9F9FA; */
                    /* border-radius: 5px; */
                    background: none !important;
                    border: none !important;
                    // padding-left: 2rem !important;
                }
            }
        }

        .public-account-input-bottom {
            width: 31.9rem;
            height: 6.4rem;
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid #F5F5F5;

            .public-account-input-bottom-left {
                width: 6rem;
                min-width: 60px;
                height: 100%;
                font-size: 1.6rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .public-account-input-bottom-center {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                .adm-input-element {
                    flex: auto;
                    display: inline-block;
                    box-sizing: border-box;
                    width: 100%;
                    max-width: 100%;
                    height: 5.4rem;
                    max-height: 100%;
                    padding: 10;
                    margin: 0;
                    color: var(--color);
                    font-size: 1.4rem;
                    border: 0;
                    outline: none;
                    appearance: none;
                    min-height: 40px;
                    text-align: var(--text-align);
                    background: none !important;
                    border: none !important;
                }
            }

            .public-account-input-bottom-right {
                min-width: 86px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-Code {
                    width: 100%;
                    height: 2.6rem;
                    min-width: 85px;
                    background: rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    font-size: 1.4rem;
                    font-weight: 400;
                    color: #000;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 2.6rem;
                    border: none;
                }

                .active {
                    background: rgba(38, 198, 166, 0.1);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #26C6A6;
                    line-height: 2.6rem;
                }
            }

        }
    }

    .public-account-agreement {
        width: 100%;
        height: 3.8rem;
        margin-top: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .public-account-agreement-box {
            height: 100%;
            width: 31.9rem;
            display: flex;
            flex-direction: row;

            .public-account-agreement-left {
                width: 1.8rem;
                height: 1.8rem;
                min-width: 13px;
                min-height: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .public-account-agreement-right {
                min-width: 240px;
                flex: 1;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                padding-left: 0.5rem;
                padding-top: 0.24rem;
                font-size: 1.4rem;
                color: #666666;

                span {
                    color: rgba(39, 200, 166, 1)
                }
            }
        }
    }

    .public-account-binding {
        width: 100%;
        height: 4.4rem;
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .public-account-binding-btn {
            height: 100%;
            width: 31.9rem;
            background: rgba(38, 198, 166, 1);
            border-radius: 2.2rem;
            border: none;
            font-size: 1.6rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }
    }

    .public-account-prompt {
        width: 100%;
        height: 2rem;
        margin-top: 1.2rem;
        font-size: 1.4rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #C5C5C5;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.head-module-header {
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .head-module-header-imgbox {
        position: absolute;
        width: 100%;
        height: 100%;

        .head-module-header-img {
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }

    .head-module-header-imgbox::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        /* 这里的0.5表示蒙层的透明度 */
        z-index: 1;
    }

    .head-module-header-back {
        width: 1200px;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;

        .head-module-header-msxzm {
            font-size: 96px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 119px;
            letter-spacing: 8px;
            margin-top: 243px;
        }
        .head-module-header-opportunity {
            width: 459px;
            height: 80px;
            background: #26C6A6;
            border-radius: 8px 8px 8px 8px;
            opacity: 0.98;
            font-size: 38px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 50px;
        }
    }
}

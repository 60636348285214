.poster_style{
    position: absolute;
    width: 100%;
    height:74.4rem;
    top: 0;
    left: 0;
    img{
        width: 100%;
        height: 100%;
    }
}
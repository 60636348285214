.cooperation-content {
    width: 100%;

    .content-module-i {
        width: 100%;
        height: 399px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #F7F8FC;

        .content-module-i-title {
            font-size: 26px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2C2D;
            margin-top: 30px;
        }

        img {
            margin-top: 20px;
            width: 339px;
            height: 283px;
        }
    }

    .content-module-two {
        width: 100%;
        height: 210px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff !important;

        .content-module-two-title {
            font-size: 26px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2C2D;
            margin-top: 30px;
        }

        img {
            width: 339px;
            height: 94px;
            margin-top: 20px;
        }
    }

    .content-module-three {
        width: 100%;
        height: 154px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F7F8FC;

        img {
            width: 339px;
            height: 94px;
        }
    }

    .content-module-four {
        width: 100%;
        height: 331px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff !important;
        img {
            width: 339px;
            height: 94px;
            margin-top: 30px;
        }
    }

    .content-module-bottom {
        width: 100%;
        height: 531px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        background: #F7F8FC !important;

        .bottom-topimg {
            width: 339px;
            height: 333px;
            position: absolute;
            top: -166.5px;

            .bottom-topimg-box {
                width: 100%;
                height: 100%;
                position: relative;

                .bottom-topimg-back {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }

                .bottom-content-module {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .bottom-top-title {
                        width: 282px;
                        height: 62px;
                        margin-top: 30px;
                    }

                    .bottom-top-button-box {
                        width: 282px;
                        height: 32px;
                        display: flex;
                        justify-content: flex-start;
                        margin-top: 21px;

                        .bottom-top-button {
                            width: 108px;
                            height: 32px;
                            background: #FFFFFF;
                            border-radius: 4px 4px 4px 4px;
                            opacity: 1;
                            font-size: 13px;
                            font-family: PingFang SC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #26C6A6;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: none;

                            .spanContent {
                                margin-right: 4px;

                                img {
                                    width: 14px;
                                    height: 14px;
                                }
                            }
                        }
                    }

                    .bottom-msx {
                        width: 320px;
                        height: 181px;
                        margin-top: 6px;
                    }
                }
            }

        }

        .content-contact-information {
            position: absolute;
            width: 339px;
            height: 345px;
            bottom: 0;

            .contact-information-title {
                width: 100%;
                height: 33px;
                font-size: 18px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #2C2C2D;
                display: flex;
                align-items: center;
            }

            .contact-information-phone {
                width: 100%;
                height: 20px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #8B8F97;
                display: flex;
                align-items: center;
                margin-top: 8px;
            }

            .contact-information-majordomo {
                margin-top: 20px;
                font-size: 18px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #2C2C2D;
                display: flex;
                align-items: center;
            }

            .contact-information-icon {
                width: 100%;
                height: 16px;
                display: flex;
                align-items: center;

                .contact-information-iconImg {
                    width: 16px;
                    height: 16px;
                    margin-top: 5px;
                }
            }

            .contact-information-code {
                width: 100%;
                height: 108px;
                display: flex;
                align-items: center;
                margin-top: 10px;

                img {
                    width: 108px;
                    height: 108px;
                }
            }
        }
        .active{
            height: 200px;
        }
    }
}
.join-cooperation {
    width: 100%;
    height: 507px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff !important;

    .join-cooperation-title {
        margin-top: 30px;
        font-size: 26px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2C2C2D;
    }

    .join-cooperation-form {
        width: 91.7%;
        height: 332px;
        margin-top: 17px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .joincooperation-input-box {
            width: 100%;
            height: 44px;
            display: flex;
            align-items: center;

            .joincooperation-input-left {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 56px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #2C2C2D;

                span {
                    color: red;
                    padding-right: 2px;
                }
            }

            .joincooperation-input-right {
                height: 100%;
                flex: 1;
                margin-left: 14px;
                display: flex;
                align-items: center;
                position: relative;

                .joincooperation-input-code {
                    position: absolute;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    right: 12px;
                    cursor: pointer;
                    background: none;
                    border: none;

                    .success {
                        color: #26C6A6;
                    }

                    .prohibit {
                        color: gray;
                    }
                }

                .icon-select {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    right: 12px;
                }

                .input-antd-style {
                    width: 100%;
                    height: 100%;
                }

                .adm-input-element {
                    font-size: 14px !important;
                    background: #F7F8FC !important;
                }

                .adm-text-area-element {
                    background: #F7F8FC;
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    font-size: 14px;
                    height: 100%;
                    width: 100%;
                    padding-left: 12px;
                    padding-top: 12px;
                }
            }
        }

        .active {
            width: 100%;
            height: 70px;

            .joincooperation-input-left {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                padding-top: 12px;
                height: 100%;
                font-size: 14px;
            }
        }

        .activeImg {
            width: 100%;
            height: 82px;

            .joincooperation-input-left {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                padding-top: 12px;
                height: 100%;
                font-size: 14px;
            }
        }
    }

    .join-cooperation-submit {
        margin-top: 18px;
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;

        .join-cooperation-button {
            width: 90.4%;
            height: 44px;
            background: #26C6A6;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: none;
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.sw-modal-alert-title{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.adm-button:not(.adm-button-default) {
    border: none;
    background: #26C6A6;
}
.wincooperation {
    width: 100%;
    height: 643px;
    display: flex;
    justify-content: center;
    align-items: center;

    .wincooperation-box {
        width: 1200px;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .wincooperation-title {
            margin-top: 80px;
            font-size: 48px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2C2D;
        }

        .wincooperation-form {
            width: 100%;
            height: 256px;
            display: flex;
            margin-top: 60px;
            justify-content: space-between;

            .wincooperation-item {
                width: 550px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                // border: 1px solid black;

                .wincooperation-input {
                    width: 100%;
                    height: 64px;
                    // background-color: skyblue;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .input-left {
                        min-width: 100px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        font-size: 24px !important;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2C2C2D;

                        span {
                            color: red;
                            margin-right: 5px;
                        }
                    }

                    .input-right {
                        flex: 1;
                        height: 100%;
                        margin-left: 24px;
                        position: relative;
                        display: flex;
                        align-items: center;

                        .input-antd-style {
                            width: 100%;
                            height: 100%;
                        }

                        .ant-input {
                            font-style: 24px !important;
                            background: #F7F8FC !important;
                            font-size: 24px !important;
                        }
                        .input-code {
                            position: absolute;
                            font-size: 24px !important;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            right: 16px;
                            background: none;
                            border: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .success{
                                color: #26C6A6;
                            }
                            .prohibit{
                                color: gray;
                            }
                        }
                    }
                }
            }

            .wincooperation-item-active {
                width: 550px;
                // background: skyblue;

                .wincooperation-input-top {
                    width: 100%;
                    height: 64px;
                    // background-color: skyblue;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .input-left {
                        min-width: 100px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        font-size: 24px !important;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2C2C2D;

                        span {
                            color: red;
                            margin-right: 5px;
                        }
                    }

                    .input-right {
                        flex: 1;
                        height: 100%;
                        margin-left: 24px;
                        position: relative;
                        display: flex;
                        align-items: center;

                        .input-antd-style {
                            width: 100%;
                            height: 100%;
                        }

                        .ant-input {
                            font-style: 24px !important;
                            background: #F7F8FC !important;
                            font-size: 24px !important;
                        }

                        .input-code {
                            position: absolute;
                            font-size: 24px !important;
                            font-family: PingFang SC-Regular, PingFang SC;
                            font-weight: 400;
                            right: 16px;
                            background: none;
                            border: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .success{
                                color: #26C6A6;
                            }
                            .prohibit{
                                color: gray;
                            }
                        }
                    }
                }

                .wincooperation-input-bottom {
                    width: 100%;
                    height: 160px;
                    margin-top: 32px;
                    display: flex;

                    .bottom-left {
                        width: 100px;
                        height: 100%;
                        // display: flex;
                        // align-items: center;
                        // justify-content: flex-end;
                        font-size: 24px !important;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2C2C2D;
                    }

                    .bottom-right {
                        flex: 1;
                        height: 100%;
                        margin-left: 24px;

                        .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
                        .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
                            width: 160px !important;
                            height: 160px !important;
                            border: none !important;
                            border-radius: 0 !important;
                            background: #F7F8FC;
                        }

                        .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
                            width: 160px !important;
                            height: 160px !important;
                        }
                    }
                }
            }
        }

        .wincooperation-button {
            width: 364px;
            height: 56px;
            background: #26C6A6 !important;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            margin-top: 44px;
            font-size: 24px !important;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: none;
        }
    }
}
.binding-success {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #F5F5F5;

    .binding-success-top {
        position: relative;
        width: 100%;
        height: 18.2rem;
        background-color: #fff;
        border: 1px solid #E2E2E2;

        .binding-success-top-icon {
            position: absolute;
            width: 100%;
            height: 6.8rem;
            top: 3.6rem;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 6.8rem;
                height: 6.8rem;
            }
        }

        .binding-success-top-title {
            position: absolute;
            width: 100%;
            height: 2.8rem;
            top: 11.9rem;
            font-size: 2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #17181D;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .binding-success-button {
        width: 100%;
        height: 4.8rem;
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .binding-success-button-btn {
            width: 35.1rem;
            height: 4.8rem;
            background: #26C6A6;
            border-radius: 6px;
            font-size: 1.8rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }
    }
}
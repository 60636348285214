.storecooperation-distinction {
    width: 100%;
    height: 472px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .storecooperation-distinction-box {
        width: 1200px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .storecooperation-distinction-title {
            font-size: 48px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2C2D;
            margin-top: 80px;
        }

        .storecooperation-distinction-modal {
            margin-top: 60px;
            width: 100%;
            height: 185px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .storecooperation-modal-item {
                width: 384px;
                height: 100%;
                background: rgba(216, 216, 216, 0);
                border-radius: 8px 8px 8px 8px;
                opacity: 1;
                border: 2px solid #26C6A6;
                display: flex;
                flex-direction: column;
                align-items: center;

                .storecooperation-modal-item-img {
                    width: 64px;
                    height: 64px;
                    background: rgba(255, 255, 255, 0.01);
                    opacity: 1;
                    margin-top: 32px;
                }

                .storecooperation-modal-item-title {
                    font-size: 32px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #2C2C2D;
                    margin-top: 24px;
                }
            }

        }
    }
}
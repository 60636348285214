.cooperation {
    width: 100%;
    height: 801px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: #F7F8FC;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    .cooperation-box {
        width: 1200px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .cooperation-title {
            margin-top: 80px;
            font-size: 48px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2C2D;
        }

        .cooperation-content-box {
            margin-top: 60px;
            width: 100%;
            height: 410px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .cooperation-left-img {
                width: 597px;
                height: 100%;
            }

            .cooperation-form {
                width: 536px;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                .cooperation-input {
                    width: 100%;
                    height: 64px;
                    display: flex;
                    align-items: center;

                    .cooperation-input-left {
                        width: 70px;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 24px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #2C2C2D;

                        span {
                            width: 15px;
                            color: red;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    .active {
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        padding-top: 10px;

                    }

                    .cooperation-input-right {
                        height: 100%;
                        margin-left: 24px;
                        flex: 1;
                        background: #F0F1F5;
                        border-radius: 8px 8px 8px 8px;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .icon-select {
                            position: absolute;
                            width: 24px;
                            height: 24px;
                            right: 20px;
                        }

                        .input-antd-style {
                            width: 100%;
                            height: 100%;
                        }

                        .ant-select-selector {
                            width: 100% !important;
                            height: 100% !important;
                            background: rgba(0, 0, 0, 0.0001) !important;
                        }

                        .ant-select-single .ant-select-selector {
                            font-size: 24px !important;
                        }

                        .ant-input {
                            font-style: 24px !important;
                            background: #F0F1F5 !important;
                            font-size: 24px !important;
                        }

                        .ant-select-selection-item {
                            display: flex !important;
                            align-items: center !important;
                        }

                        .ant-select-selection-placeholder {
                            display: flex;
                            align-items: center;
                            color: #8B8F97;
                        }
                    }
                }

                .active {
                    width: 100%;
                    height: 122px;
                }
            }
        }

        .cooperation-submit-button {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 38px;

            .cooperation-button {
                width: 412px;
                height: 64px;
                background: #26C6A6 !important;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                font-size: 24px !important;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
            }
        }
    }
}
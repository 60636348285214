.cooperationprocess {
    width: 100%;
    height: 490px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(140deg, #51DFBE 0%, #1AC7A4 100%);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;

    .cooperationprocess-img {
        width: 1200px;
        height: 290px;
    }
}
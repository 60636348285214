.successBox {
  position: absolute;
  width: 100%;
  height: 100%;
  // background-color: red;
  display: flex;
  flex-direction: column;

  .successBox_top {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    // background-color: red;
    .imgBOX {
      width: 70%;
      height: 90%;
      display: flex;
      flex-direction: column;
      // background-color: black;

      // align-items: center;
      .imgTop {
        width: 100%;
        height: 70%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        // background-color: blue;

        img {
          width: 200px;
          height: 180px;
        }
      }

      .imgTitle {
        flex: 1;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #26c6a6;
        line-height: 20px;
        text-align: center;
      }
    }
  }

  .successBox_bottom {
    flex: 1;
    // background-color: aquamarine;
    position: relative;
    min-height: 300px;

    .backsuccess {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .code-box {
      position: absolute;
      width: 100%;
      top: 20%;
      height: 260px;

      .QRCCODE {
        // position: absolute;
        // width: 100%;
        // height: 160px;
        // background-color: #26C6A6;
        // top: 20%;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-around;
        // align-items: center;

        // .Two-dimensional_code {
        //   width: 100px;
        //   height: 100px;
        //   // background-color: black;
        //   overflow: hidden;
        //   // img {
        //   //     width: 100%;
        //   //     height: 100%;
        //   // }
        // }

        width: 100%;
        height: 160px;
        // background-color: skyblue;

        display: flex;
        justify-content: center;
        align-items: center;

        .environment-code {
          width: 160px;
          height: 160px;
          overflow: hidden;
          background: rgba(255, 255, 255, 0.3);
          border-radius: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          .environment-code-box {
            width: 152px;
            height: 152px;
            background: #FFFFFF;
            border-radius: 14px;
            display: flex;
            justify-content: center;
            align-items: center;

            .environment-code-img {
              width: 135px;
              height: 135px;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }

    .download_iosorandroid {
      position: absolute;
      top: 60%;
      width: 100%;
      height: 100px;
      // background-color: skyblue;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      &.in_mini {
        top: 35%;
      }

      .downloadType {
        width: 150px;
        height: 50px;
        // background: #ffffff;
        // box-shadow: 0px 1px 6px 0px rgba(0, 104, 99, 0.12), inset 0px 0px 9px 0px rgba(0, 193, 173, 0.25);
        border-radius: 19px;
        // border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #26c6a6;
        line-height: 18px;

        // img {
        //     width: 30px;
        //     height: 30px;
        // }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .successBox_bottom_text {
      position: absolute;
      top: 60%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;

      &_line1 {
        font-size: 18px;
        margin-bottom: 1rem;
      }

      &_line2 {
        font-size: 13px;
      }
    }
  }
}
.furnish {
    width: 100%;
    height: 700px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .furnish-back {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
    }

    .furnish-box {
        width: 1200px;
        height: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        position: relative;

        .furnish-title {
            font-size: 48px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2C2D;
            margin-top: 80px;
        }

        .furnish-content-img {
            width: 100%;
            height: 493px;
            position: absolute;
            bottom: 0;
        }
    }
}
.details-cooperation {
    width: 100%;
    height: 714px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F7F8FC;

    .details-cooperation-box {
        width: 1200px;
        height: 100%;
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;

        .details-cooperation-title {
            margin-top: 80px;
            font-size: 48px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2C2D;
        }

        .details-cooperation-img {
            margin-top: 40px;
            width: 100%;
            height: 447px;
        }
    }
}
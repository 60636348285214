.FloatButton_box {
    position: fixed;
    bottom: 90px;
    right: 43px;
    width: 67px;
    height: 210px;
    // background-color: red;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    .floatButton_box_ys {
        position: relative;
        flex: 1;
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);

        div {
            position: absolute;
            width: 100%;
            height: 15px;
            // background-color: red;
            bottom: 5px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #93979E;
            display: flex;
            justify-content: center;
            align-items: center;

        }
    }
}

.FloatButtonDIV {
    width: 310px;
    height: 150px;
    position: absolute;
    // background-color: red;
    right: 60px;
    top: -37px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.88);
    background-color: rgba(255, 255, 255, 1);
    line-height: 1.5714285714285714;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    cursor: pointer;
    overflow: hidden;
    z-index: 99;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

    .Customerservicetype {
        flex: 1;
        margin-top: 2px;
        margin-bottom: 2px;
        // background-color: pink;
        display: flex;
        flex-direction: row;

        .Customerservicetype_left {
            width: 50px;
            height: 100%;
            // background-color: blue;
            display: flex;
            justify-content: center;
            align-items: center;

            div {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;

                .leftIcon {
                    width: 25px;
                    height: 25px;
                    filter: drop-shadow(#21D3A2 80px 0);
                }

                &:hover {
                    .leftIcon {
                        margin-left: -160px;
                    }
                }
            }
        }

        .Customerservicetype_center {
            flex: 1;
            display: flex;
            flex-direction: column;

            .Customerservicetype_center_top {
                flex: 1;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #2A2A2A;
                line-height: 22px;
            }

            .Customerservicetype_center_bottom {
                flex: 1;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #26C6A6;
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .Customerservicetype_center_bottoms {
                flex: 1;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2A2A2A;
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .Customerservicetype_riht {
            width: 30px;
            height: 100%;
            // background-color: red;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 17px;
                height: 17px;
            }
        }
    }

    .Customerservicetype:hover {
        background-color: rgba(0, 0, 0, 0.05);
        transition: .5s;

        &:hover {
            .leftIcon {
                margin-left: -160px;
            }
        }
    }
}
.zskfCancel {
    position: absolute;
    bottom: -50px;
    width: 100%;
    height: 40px;
    // background-color: red;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
}

.formcooperation {
    width: 100%;

    .formcooperation-ASSETS {
        width: 100%;
        height: 525px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F7F8FC;

        .formcooperation-ASSETSbox {
            width: 1200px;
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            .formcooperation-title {
                margin-top: 80px;
                font-size: 48px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #2C2C2D;
            }

            .formcooperation-img {
                width: 1200px;
                height: 221px;
                margin-top: 60px;
            }
        }
    }

    .formcooperation-form {
        width: 100%;
        height: 672px;
        display: flex;
        align-items: center;
        justify-content: center;

        .formcooperation-form-box {
            width: 1200px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .formcooperation-form-title {
                margin-top: 80px;
                font-size: 48px;
                font-family: PingFang SC-Semibold, PingFang SC;
                font-weight: 600;
                color: #2C2C2D;
            }

            .formcooperation-form-img {
                width: 1200px;
                height: 365px;
                margin-top: 80px;
            }
        }
    }

    .formcooperation-effect {
        width: 100%;
        height: 525px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F7F8FC;

        img {
            width: 1200px;
            height: 365px;
        }
    }

    .formcooperation-welcome {
        width: 100%;
        height: 735px;
        display: flex;
        justify-content: center;

        img {
            width: 1200px;
            height: 365px;
            margin-top: 80px;
        }
    }

    .formcooperation-bottom {
        width: 100%;
        height: 589px;
        display: flex;
        justify-content: center;
        position: relative;
        background: #F7F8FC;

        .formcooperation-bottom-top {
            width: 1200px;
            height: 380px;
            position: absolute;
            top: -190px;

            .formcooperation-bottom-top-box {
                width: 100%;
                height: 100%;
                position: relative;

                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }

                .formcooperation-bottom-top-button {
                    cursor: pointer;
                    width: 206px;
                    height: 62px;
                    position: absolute;
                    top: 223px;
                    left: 50px;

                    .button-img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

        }

        .formcooperation-bottom-bo {
            width: 1200px;
            height: 199px;
            position: absolute;
            top: 270px;
            display: flex;
            justify-content: flex-start;

            .footer-left {
                height: 100%;
                width: 410px;

                .footer-left-connection {
                    margin-left: 66px;
                    font-size: 32px;
                    font-weight: 600;
                    color: #2C2C2D;
                }

                .footer-left-tion {
                    margin-left: 66px;
                    margin-top: 12px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #8B8F97;
                }
            }

            .footer-center {
                width: 126px;
                height: 100%;

                .footer-center-top {
                    font-size: 24px;
                    font-weight: 400;
                    color: #2C2C2D;

                }

                .footer-center-center {
                    margin-top: 8px;
                    width: 20px;
                    height: 20px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .footer-center-bottom {
                    width: 126px;
                    height: 126px;
                    background: #FFFFFF;
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    margin-top: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 110px;
                        height: 100px;
                    }
                }

            }

            .footer-right {
                flex: 1;

                .footer-right-phoneTitle {
                    margin-left: 246px;
                    font-size: 24px;
                    font-weight: 400;
                    color: #2C2C2D;
                }

                .footer-right-phone {
                    margin-left: 246px;
                    margin-top: 40px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #8B8F97;
                }

                .footer-right-email {
                    margin-left: 246px;
                    margin-top: 24px;
                    font-size: 18px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #8B8F97;
                }

                .footer-right-address {
                    margin-left: 246px;
                    margin-top: 24px;
                    font-size: 18px;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    color: #8B8F97;
                }
            }
        }
    }
}
.headerContent {
    position: relative;
    width: 100%;
    height: 34.01rem;

    // background-color: red;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .btn_box {
        position: absolute;
        width: 100%;
        // height: 60px;
        height: 4.8rem;
        // background-color: pink;
        // z-index: 9999;
        top: 65%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        .btn_left_box {
            // width: 160px;
            // height: 40px;
            width: 14.2rem;
            height: 3.8rem;
            // width: 40%;
            // height: 70%;
            margin-left: 30px;

            .btn1 {
                width: 100%;
                height: 100%;
                background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
                box-shadow: 4px 4px 9px 0px rgba(106, 149, 145, 0.1), -4px -4px 9px 0px rgba(255, 255, 255, 0.1);
                border-radius: 2px;
                border: 1px solid rgba(255, 255, 255, 0.29);
                font-size: 1.5rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
            }
        }

        .btn_right_box {
            // width: 160px;
            // height: 40px;
            width: 14.2rem;
            height: 3.8rem;
            margin-right: 30px;
            // background-color: red;

            .btn2 {
                width: 100%;
                height: 100%;
                background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
                box-shadow: 4px 4px 9px 0px rgba(106, 149, 145, 0.1), -4px -4px 9px 0px rgba(255, 255, 255, 0.1);
                border-radius: 2px;
                border: 1px solid rgba(255, 255, 255, 0.29);
                font-size: 1.5rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
            }
        }

        .btn_right_box2 {
            // width: 160px;
            // height: 40px;
            width: 21.8rem;
            height: 3.8rem;
            margin-left: 30px;
            // background-color: red;

            .btn2 {
                width: 100%;
                height: 100%;
                background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
                box-shadow: 4px 4px 9px 0px rgba(106, 149, 145, 0.1), -4px -4px 9px 0px rgba(255, 255, 255, 0.1);
                border-radius: 2px;
                border: 1px solid rgba(255, 255, 255, 0.29);
                font-size: 1.5rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
            }
        }
    }
}

.Carousel_phone {
    width: 100%;
    height: 34rem;
    // background-color: rgba(106, 149, 145, 0.5);
    margin-top: -50px;

    .contentSwiper {
        height: 30rem;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        user-select: none;
        // background-color: red;

        .Carousel_box {
            margin: 0;
            height: 30rem;
            color: #fff;
            // background: #364d79;
            display: flex;
            flex-direction: column;
            align-items: center;

            .Carousel_box_tx {
                width: 100%;
                height: 10rem;
                // background-color: red;
                display: flex;
                justify-content: center;
                align-items: center;

                .divimgbox {
                    width: 9.6rem;
                    height: 9.6rem;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        margin-top: 2px;
                    }
                }

            }

            .Carousel_box_jr {
                width: 100%;
                height: 5rem;
                // background-color: pink;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.8rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #2A2A2A;
                line-height: 25px;
            }

            .Carousel_box_js {
                width: 96%;
                height: 9.6rem;
                // background-color: skyblue;
                font-size: 13px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #4F5156;
                line-height: 22px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                //         overflow: hidden;
                // text-overflow: ellipsis;
                // white-space: nowrap;
            }

            .Carousel_box_name {
                width: 100%;
                height: 4rem;

                // background-color: pink;
                margin-top: -1rem;
                display: flex;
                justify-content: center;
                align-items: center;

                .Carousel_box_name_hx {
                    flex: 1;
                    height: 100%;
                    // background-color: red;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    div {
                        width: 44px;
                        height: 1px;
                        background: #DCDEE2;
                    }
                }

                .Carousel_box_name_hxs {
                    flex: 1;
                    height: 100%;
                    // background-color: red;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    div {
                        width: 44px;
                        height: 1px;
                        background: #DCDEE2;
                    }
                }

                .Carousel_box_name_hx_name {
                    width: 5rem;
                    height: 100%;
                    // background-color: yellow;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.2rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #8E9196;
                    line-height: 17px;
                }
            }
        }
    }


}

.joiningprocessPhone {
    width: 100%;
    height: 50px;
    background-color: rgba(248, 248, 248, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
    line-height: 25px;
}

.OperationprocessPhone {
    width: 100%;
    height: 100px;
    background-color: rgba(248, 248, 248, 1);
    display: flex;
    justify-content: center;
    align-items: center;

    .modal_Operationprocess {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .modal_Operationprocess_some {
            width: 20rem;
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            // background-color: red;

            .modal_Operationprocess_some_icon {
                width: 90%;
                height: 70px;

                img {
                    width: 100%;
                    height: 100%;
                    cursor: pointer;
                }
            }

            .modal_Operationprocess_some_title {
                flex: 1;
                text-align: center;
                font-size: 1.2rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #58595D;
                line-height: 17px;
            }
        }

        .modal_Operationprocess_somes {
            width: 6rem;
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // background-color: green;

            .modal_Operationprocess_some_icon {
                width: 100%;
                height: 6.5rem;
                // background-color: black;
                margin-top: 13px;

                img {
                    width: 100%;
                    height: 70%;
                    cursor: pointer;
                }
            }

            .modal_Operationprocess_some_title {
                flex: 1;
                text-align: center;
                font-size: 1.2rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #58595D;
                line-height: 17px;
            }
        }
    }
}

.why_msxPhone {
    width: 100%;
    height: 50px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
    line-height: 25px;
}

.whyjoin_explain {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 400px;

    .whyjoin_explain_box {
        width: 90%;
        height: 30%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: #F9F9FA;
        // background-color: red;

        // align-items: center;
        .whyjoin_explain_box_leftIcon {
            width: 45%;
            height: 90%;
            // background-color: skyblue;
            img {
                width: 100%;
                height: 100%;
                margin-left: -10px;
            }
        }

        .whyjoin_explain_box_right {
            flex: 1;
            display: flex;
            flex-direction: column;
            // background-color: black;
            margin-left: -10px;
            overflow: hidden;

            .whyjoin_explain_box_right_conTop {
                width: 100%;
                height: 20%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                font-size: 15px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #4F5156;
                line-height: 21px;
                // background-color: blue;
                margin-top: 20px;
            }

            .hxpartition {
                width: 12%;
                height: 2px;
                background: #26C6A6;
                border-radius: 1px;
                margin-top: 6px;
            }

            .whyjoin_explain_box_right_conBottom {
                flex: 1;
                width: 95%;
                display: flex;
                justify-content: flex-start;
                align-items: start;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #93979E;
                line-height: 17px;
                // background-color: #21D3A2;
                padding-top: 10px;
            }
        }
    }
}

.bottom_kBOX {
    width: 100%;
    height: 80px;
}

.onX {
    position: absolute;
    right: 20px;
    width: 30px;
    height: 30px;
    z-index: 999;

    img {
        width: 100%;
        height: 100%;
    }
}

.headerTitle {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
    line-height: 25px;
}

.ljjrBox {
    position: absolute;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;
    left: -23%;
    bottom: 0;
    // top: -10px;
}

.consultation {
    position: absolute;
    width: 90%;
    height: 70%;
    // background-color: red;
    top: 20px;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;

    .consultation_con {
        flex: 1;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .consultation_con_left {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 1.6rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
        }

        .consultation_con_right {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            cursor: pointer;

            a {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 17px;
            }

            img {
                width: 18px;
                height: 18px;
            }
        }

        .consultation_con_right:hover {
            color: rgba(0, 0, 0, 1);
        }
    }
    .action{
        border: none;
    }
    .consultation_cons {
        flex: 1;
        display: flex;
        flex-direction: row;
        // border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .consultation_con_left {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 1.6rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
        }

        .consultation_con_right {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            cursor: pointer;

            a {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 17px;
            }

            img {
                width: 18px;
                height: 18px;
            }
        }

        .consultation_con_right:hover {
            color: rgba(0, 0, 0, 1);
        }
    }
}

.lineconsultation {
    position: absolute;
    width: 100%;
    height: 70%;
    // background-color: red;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;

    .Lineconsultation_con {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    .Lineconsultation_conhm {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
    }

    .Lineconsultation_conhx {
        width: 100%;
        height: 5px;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .Lineconsultation_conhj {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            display: inline-block;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 25px;
        }
    }

    .Lineconsultation_cons {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
    }
}

.pdBtn {
    position: fixed;
    width: 100%;
    height: 60px;
    // background-color: red;
    bottom: 20px;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .btn_left_box {
        // width: 160px;
        // height: 40px;
        width: 14.2rem;
        height: 3.8rem;
        margin-left: 30px;

        .btn1 {
            width: 100%;
            height: 100%;
            background: linear-gradient(105deg, #49E7C5 0%, #21D3A2 100%);
            box-shadow: 4px 4px 9px 0px rgba(106, 149, 145, 0.1), -4px -4px 9px 0px rgba(255, 255, 255, 0.1);
            border-radius: 2px;
            border: 1px solid rgba(255, 255, 255, 0.29);
            font-size: 1.5rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }
    }

    .btn_right_box {
        // width: 160px;
        // height: 40px;
        width: 14.2rem;
        height: 3.8rem;
        margin-right: 30px;

        .btn2 {
            width: 100%;
            height: 100%;
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            box-shadow: 4px 4px 9px 0px rgba(106, 149, 145, 0.1), -4px -4px 9px 0px rgba(255, 255, 255, 0.1);
            border-radius: 2px;
            border: 1px solid rgba(255, 255, 255, 0.29);
            font-size: 1.5rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }
    }

    .btn_right_box2 {
        // width: 160px;
        // height: 40px;
        width: 21.8rem;
        height: 3.8rem;
        margin-left: 30px;

        .btn2 {
            width: 100%;
            height: 100%;
            background: linear-gradient(282deg, #FF7D00 0%, #F7AF37 100%);
            box-shadow: 4px 4px 9px 0px rgba(106, 149, 145, 0.1), -4px -4px 9px 0px rgba(255, 255, 255, 0.1);
            border-radius: 2px;
            border: 1px solid rgba(255, 255, 255, 0.29);
            font-size: 1.5rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
        }
    }
}

.custom-class {
    height: 300px !important;
}

.XonClose {
    position: absolute;
    width: 40px;
    height: 40px;
    // background-color: red;
    top: 4px;
    right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100% !important;
    height: 36px !important;
    padding: 0 11px !important;
}

.ant-drawer-close {
    position: absolute !important;
    right: 0 !important;
}

.ant-drawer-body::-webkit-scrollbar {
    width: 0 !important
}

.ant-drawer-header {
    border: none !important;
}

.ant-drawer-content {
    // border-radius: 20px !important;
    border-radius: 20px 20px 0 0 !important;
}

.ant-drawer {
    z-index: 999 !important;
}

.ant-drawer .ant-drawer-content {
    z-index: 999 !important;
}

.ant-drawer-body {
    z-index: 999 !important;
}

.formBox {
    width: 100% !important;
    height: 100% !important;

    // background-color: #21D3A2;
    // overflow: hidden;
    .ant-form-item-label {
        flex: 0 0 30% !important;
        max-width: 100% !important;
        padding-right: 15px !important;
        display: flex !important;
        justify-content: flex-end !important;
        align-items: flex-start !important;
        padding-top: 1px !important;
    }

    .ant-form-item-control {
        flex: 0 0 70% !important;
        padding-top: 1px !important;
    }
}

.ant-drawer .ant-drawer-title {
    text-align: center !important;
    font-size: 18px !important;
    font-family: PingFangSC-Semibold, PingFang SC !important;
    font-weight: 600 !important;
    color: #2A2A2A !important;
    line-height: 25px !important;
}

.ant-form-item-explain-error {
    width: 250px !important;
}

.ant-float-btn-default .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
    width: 100% !important;
    height: 100% !important;
}

.ant-float-btn-group-circle .ant-float-btn-circle:not(:last-child) .ant-float-btn-body {
    width: 61px !important;
    height: 50px !important;
}

.ant-message-notice-content {
    // width: 30%;
    height: 25%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 16px !important;
    // background-color: khaki;
}

.ant-carousel .slick-dots-bottom {
    bottom: 0px !important;
}

// .ant-upload.ant-upload-select{
//     display: none;
// }




.Popup_title {
    width: 100%;
    height: 30px;
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2A2A2A;
    margin-top: 20px;
}

.PopupForm {
    width: 100%;
    height: 100%;
    // background-color: red;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .PopupForm_box {
        // flex: 1;
        width: 100%;
        height: 50px;
        margin-top: 1rem;
        // background-color: skyblue;
        display: flex;
        flex-direction: row;
        min-height: 40px;

        .submitBtn {
            height: 40px;
            // background: #26C6A6;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .PopupForm_box_left {
            width: 10rem;
            height: 100%;
            // background-color: #21D3A2;
            font-size: 1.4rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4F5156;
            line-height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 20px;

            span {
                color: red;
            }
        }

        .PopupForm_box_right {
            height: 100%;
            width: 28.4rem;
            // background-color: gray;
            display: flex;
            flex: 1;
            justify-content: flex-start;
            align-items: center;
            margin-right: 2.5rem;

            .fsyzm {
                width: 9.1rem;
                height: 4rem;
                background: #F9F9FA;
                border-radius: 5px;
                font-size: 1.4rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #26C6A6;
                padding: .7rem .0rem;
                // line-height: 20px;
                margin-left: .8rem;
            }

            .adm-input {
                width: 100%;
                flex: 1;
                height: 4rem;
            }

            .adm-text-area-element {
                background: #F9F9FA;
                border-radius: 5px;
                font-size: 1.4rem;
                line-height: 2rem;
                padding-left: 1rem;
                padding-top: 1.3rem;
                height: 8rem;
            }

            .adm-input-element {
                flex: auto;
                display: inline-block;
                box-sizing: border-box;
                width: 100%;
                max-width: 100%;
                max-height: 100%;
                padding: 10;
                margin: 0;
                color: var(--color);
                font-size: 1.4rem;
                line-height: 1.5;
                background: transparent;
                border: 0;
                outline: none;
                appearance: none;
                min-height: 4rem;
                text-align: var(--text-align);
                background: #F9F9FA;
                height: 4rem;
                // max-height: 30px;
                border: 1px solid #F9F9FA;
                border-radius: 5px;
            }
        }
    }

    .PopupForm_boxs {
        // flex: 1;
        width: 100%;
        height: 50px;
        margin-top: 5.8rem;
        // background-color: skyblue;
        display: flex;
        flex-direction: row;

        .submitBtn {
            height: 40px;
            // background: #26C6A6;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .PopupForm_box_left {
            width: 10rem;
            height: 100%;
            // background-color: #21D3A2;
            font-size: 1.4rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #4F5156;
            line-height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 20px;

            span {
                color: red;
            }
        }

        .PopupForm_box_rights {
            flex: 1;
            // background-color: gray;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .PopupForm_box_rightsIMg {
                width: 7.2rem;
                height: 7.2rem;
                // background-color: red;
            }

            .fsyzm {
                width: 11.1rem;
                height: 43px;
                background: #F9F9FA;
                border-radius: 4px;
                font-size: 1.4rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #26C6A6;
                // line-height: 20px;
                margin-left: 1.2rem;
            }

            .adm-input {
                width: 93%;
            }

            .adm-text-area-element {
                background: #F9F9FA;
                border-radius: 5px;
                margin-left: 1.2rem;
                font-size: 1.4rem;
                padding-left: 1rem;
                padding-top: 10px;
                height: 8rem;
            }

            .adm-input-element {
                flex: auto;
                display: inline-block;
                box-sizing: border-box;
                width: 100%;
                max-width: 100%;
                max-height: 100%;
                padding: 10;
                margin: 0;
                color: var(--color);
                font-size: 1.4rem;
                line-height: 1.5;
                background: transparent;
                border: 0;
                outline: none;
                appearance: none;
                min-height: 40px;
                text-align: var(--text-align);
                background: #F9F9FA;
                height: 40px;
                // max-height: 30px;
                border: 1px solid #F9F9FA;
                border-radius: 5px;
                padding-left: 1rem !important;
            }
        }
    }

    .PopupForm_boxbtn {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 40px;

        .submitBtn {
            width: 90%;
            height: 40px;
            background: #26C6A6;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
        }
    }

    &::-webkit-scrollbar {
        display: none
    }
}

.popup_GZQY {
    width: 100%;
    height: 100%;
    // background-color: skyblue;

    .workinggarea {
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: skyblue;
        display: flex;
        flex-direction: column;
        align-items: center;

        .workinggarea_quanxaun {
            position: relative;
            width: 100%;
            height: 15.4rem;
            // background-color: green;

            .backImg {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            .top_title {
                position: absolute;
                width: 100%;
                height: 4.4rem;
                top: 3rem;
                left: 0;
                // background-color: red;
                font-size: 1.8rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                text-align: center;

                img {
                    position: absolute;
                    top: 0;
                    left: 1.4rem;
                    width: 2.2rem;
                    height: 2.2rem;
                }
            }

            .bottom_title {
                position: absolute;
                width: 35.1rem;
                height: 5.8rem;
                bottom: 0;
                left: 1.5rem;
                background: #FFFFFF;
                box-shadow: 0px 2px 15px 0px rgba(0, 133, 107, 0.05);
                border-radius: 8px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .bottom_title_left {
                    height: 100%;
                    width: 11rem;
                    // background-color: red;
                    display: flex;
                    // flex-direction: row;
                    // justify-content:space-evenly;
                    align-items: center;
                    font-size: 1.5rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    padding-left: 2rem;

                    img {
                        width: 1.6rem;
                        height: 1.6rem;
                        margin-left: -1.7rem;
                    }

                }

                .bottom_title_right {
                    height: 100%;
                    width: 11rem;
                    // background-color: red;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.4rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    padding-right: 0.8rem;
                }
            }

            img {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            .selectall {
                width: 31.5%;
                height: 80%;
                // background-color: red;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
            }
        }

        .popup_SM {
            width: 100%;
            height: 8rem;
            // background-color: #21D3A2;
            display: flex;
            flex-direction: column;

            .popup_SM_top_title {
                flex: 1;
                display: flex;
                align-items: flex-end;
                padding-left: 0.8rem;
                font-size: 1.6rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
            }

            .popup_SM_bottom_title {
                flex: 1;
                display: flex;
                align-items: flex-start;
                // padding-left: 0.5rem;
                font-size: 1.3rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
            }
        }

        .workinggarea_top {
            width: 94%;
            flex: 1;
            // background-color: pink;
        }

        .workinggarea_bottom {
            width: 90%;
            height: 100px;
            // background-color: red;
            display: flex;
            justify-content: center;
            align-items: center;

            .workinggarea_bottom_class {
                width: 35.1rem;
                height: 4.8rem;
                background: #26C6A6;
                border-radius: 8px;
                border: none;
            }
        }
    }
}

.popup_FUJN {
    width: 100%;
    height: 100%;
    // background-color: skyblue;
    display: flex;
    flex-direction: column;

    .popup_Title {
        width: 100%;
        height: 17.4rem;
        background-color: #ffffff;
        position: relative;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .popup_Title_fh {
            position: absolute;
            width: 100%;
            height: 4.4rem;
            // background-color: red;
            top: 2.4rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            // color: #FFFFFF;
            // line-height: 25px;
            font-size: 1.8rem;
            text-align: center;
            color: #ffffff;

            .left_fjh {
                position: absolute;
                height: 2.2rem;
                width: 2.2rem;
                // background-color: #21D3A2;
                top: 0;
                // font-size: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 1.4rem;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .popup_box {
            position: absolute;
            width: 35.1rem;
            height: 8.2rem;
            bottom: 0;
            left: 1.4rem;
            background: #FFFFFF;
            box-shadow: 0px 2px 15px 0px rgba(0, 133, 107, 0.05);
            border-radius: 8px;
            display: flex;
            flex-direction: column;

            .popup_box_top {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.8rem;
            }

            .popup_box_bottom {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.4rem;
            }
        }
    }

    .popup_content {
        margin-top: 1rem;
        flex: 1;
        // background-color: #21D3A2;

        .container {
            height: 100%;
            background-color: #ffffff;
            display: flex;
            justify-content: flex-start;
            align-items: stretch;

            [data-prefers-color='dark'] & {
                background-color: unset;
            }
        }

        .side {
            flex: none;
        }

        .main {
            flex: auto;
            // background-color: skyblue;
            margin-left: 1rem;
        }

        .content {
            height: 100%;
            display: none;
            justify-content: center;
            align-items: center;
            font-size: 32px;
            color: #cccccc;

            &.active {
                display: flex;
            }
        }

        .count {
            color: red;
            padding-left: 4px;
        }

        .adm-side-bar-item-active {
            color: green;
        }

        .adm-side-bar-item {
            font-size: 16px;
            // font-weight: 600;
        }

        .adm-side-bar-item {
            color: gray;
        }
    }

    .popup_FUJN_boxbtn {
        width: 100%;
        height: 8.9rem;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;

        .submitBtns {
            width: 35.1rem;
            height: 4.8rem;
            background: #26C6A6;
            border-radius: 8px;
            border: none;
        }
    }
}


.SideBar_context {
    background-color: red;
    width: 100px;
    height: 300px;
}

.adm-selector-item {
    padding: var(--padding);
    position: relative;
    // background-color: #F5F5F5;
    border: var(--border);
    color: var(--text-color);
    opacity: 1;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    vertical-align: top;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    // margin-left: 0.5rem;
    // margin-right: 1.4rem;
    width: 11rem;
    height: 5rem;
    font-size: 1.4rem;
}

.adm-selector-item .adm-selector-check-mark-wrapper {
    border: none;
    // background-color: #26C6A6;
}

.adm-selector-item {
    // border: 1px solid #26C6A6;
}

.adm-selector-item-multiple-active {
    background: rgba(38, 198, 166, 0.1);
    border-radius: 4px;
    border: 1px solid #26C6A6;
    color: #26C6A6;
}


.adm-page-indicator-dot {
    display: block;
    width: var(--dot-size);
    height: var(--dot-size);
    border-radius: var(--dot-border-radius);
    // background: var(--dot-color);
    width: 10px;
    height: 10px;
    border-radius: 10px;
}

.adm-page-indicator-dot-active {

    background: #21D3A2;

}


.adm-popup-body {
    position: fixed;
    background-color: var(--adm-color-background);
    z-index: calc(var(--z-index) + 10);
    border: 20px 20px 0 0;
    border-radius: 20px 20px 0 0;
}



.zufs {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-left: 1.8rem;
    margin-right: 2rem;
    margin-top: 1.4rem;
    line-height: 2.5rem;
}

a {
    text-decoration: none;
}

// .adm-popup-body{
//     overscroll-behavior: contain;
// }


.adm-input-element {
    flex: auto;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    padding: 10;
    margin: 0;
    color: var(--color);
    font-size: 1.4rem;
    line-height: 1.5;
    background: transparent;
    border: 0;
    outline: none;
    appearance: none;
    min-height: 40px;
    text-align: var(--text-align);
    background: #F9F9FA;
    height: 40px;
    // max-height: 30px;
    border: 1px solid #F9F9FA;
    border-radius: 5px;
    padding-left: 1rem !important;
}
.face-problems {
    width: 100%;
    height: 234px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;

    .face-problems-title {
        font-size: 26px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2C2C2D;
        margin-top: 30px;
    }
    .face-problems-content{
        width: 338px;
        height: 118px;
        margin-top: 20px;
        img{
            width: 100%;
            height: 100%;
        }
    }
}
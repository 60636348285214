.header-business-cooperation {
    width: 100%;

    // height: 220px;
    .business-cooperation-top {
        width: 100%;
        height: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        .business-cooperation-title {
            margin-top: 66px;
            font-size: 35px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .business-cooperation-button {
            margin-top: 14px;
            width: 179px;
            height: 33px;
            background: #26C6A6;
            border-radius: 4px 4px 4px 4px;
            opacity: 0.98;
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .business-cooperation-introduce {
        width: 100%;
        height: 243px;
        background: #F7F8FC;
        display: flex;
        flex-direction: column;
        align-items: center;

        .introduce-title {
            margin-top: 30px;
            font-size: 26px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2C2D;
        }

        .introduce-img {
            width: 339px;
            height: 127px;
            margin-top: 20px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

}
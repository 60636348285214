.advantage {
    width: 100%;
    // height: 801px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // position: relative;
    // background: #F7F8FC;
    // border-radius: 0px 0px 0px 0px;
    // opacity: 1;
    .advantage-box-title {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .advantage-title {
            width: 1200px;
            height: 147px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            font-size: 48px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #2C2C2D;
        }
    }

    .advantage-box {
        width: 100%;
        height: 525px;
        display: flex;
        justify-content: center;
        align-items: center;

        .advantage-content {
            width: 1200px;
            height: 365px;
        }
    }
    .active {
        background: #F7F8FC;
    }

}